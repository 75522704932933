<template>
  <div class="has-text-centered">
    
    <h1 class="title is-1">{{locoProp}}</h1>

    <div v-if="locoProp != ''">

    <div class="columns is-multiline" >
      
      <div class="column is-4" v-for="i in filterLoco" :key="i">

    <!-- Testing Box layout -->
      <div class="box is-family-sans-serif">
        <article class="media ">
          <div class=" bgBdrop media-left">
          
            <span class="title is-1">{{ i.bloodType }}</span><font-awesome-icon :icon="mapType(i.RH)" /> 
            
          </div>
          <div class="media-content pt-4">
            <div :class=" amtObject(i.Available) " class="unitsBox">
              <span class="subtitle is-3 ">
                {{ i.Available }}
                </span>
                 <small class="has-text-weight-bold">unit/s</small>
            </div>
            <div class="pt-4">
              <small>
                <font-awesome-icon icon="calendar-days" />  {{ i.date }}  || <font-awesome-icon icon="clock" />  {{ i.time }}
              </small>
            </div>
            
          </div>
        </article>
      </div>
    <!-- End testing Box layout  -->
    </div>
  </div>

        <img src="@/assets/droplet.png" width="230" alt="" />

  </div> <!-- end locoProp if -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  
name: "list",
props:{
    locoProp: String,
  },
  data() {
    return {
      Hospital:""
    };
  },
  computed: {
    ...mapState(["inventory"]),
    
    
    filterLoco(){
      let Hospital = this.locoProp;
      if (this.locoProp == "EMCP"){
         Hospital = "AEMC"
      }
      // https://masteringjs.io/tutorials/fundamentals/filter-array-of-objects
      return this.inventory.filter(hos => hos.location === Hospital);
    }
    
  },
  methods:{
    amtObject(amt) {
      let amt2 = amt
      if (amt2 < 10){
        return 'has-background-danger-light has-text-danger'
      }else if (amt2 <30 && amt2 >10){
        return 'has-background-warning-light has-text-black'
      }else{
        return 'has-background-success-light has-text-success'
      }
    },
    mapType(type) {
     let RH = type
      if (RH === 'NEG'){
        return 'minus'
      }else if ( RH === 'POS'){
        return 'add'
      }else {
        return 'question'
      }
      
    },


  },
  mounted() {
    if (!Array.isArray(this.inventory) || !this.inventory.length) {
      this.$store.dispatch("getInv");
      // this.setLoading();
    }
  },
};
</script>

<style lang="css" scoped>

.bgBdrop{
  background-image: url("@/assets/droplet.png");
  background-size: 50% ;
  background-repeat: no-repeat;
height: 120px;
width: 120px;
}
.unitsBox {
  border-radius: .7em;
  
}

</style>
