import axios from "axios";

// Note to self CF does not like using anything but the header I have un commented if i use the others it adds a : at the end of the json string, which cause major erros

const apiClient = axios.create({
  // baseURL: "http://127.0.0.1:59169/BloodBank/bbApi.cfc",
  // API lucee server on aws
 baseURL: "http://ec2-3-84-23-108.compute-1.amazonaws.com/tasks/BloodBank",

  headers: {
    Accept: "application/json",
    "Content-Type": "application/JSON",
    "Content-type": "application/x-www-form-urlencoded", // remember to comment this out if on CF need to figure out cors for lucee
    // "Content-type": "multipart/form-data"
  },
});

export default {
  logMein(userAuth) {
    console.log(userAuth);
    return apiClient.post("?method=processLogin", userAuth);
  },
  addGrivence(grevenceData) {
    console.log(grevenceData);
    return apiClient.post("?method=insGrivence", grevenceData);
  },
  getBloods() {
    return apiClient.get("bbApi.cfc?method=getBBdata");
  },
  getAssetFiles(aID) {
    return apiClient.get("media/" + aID);
  },
  addNMcomments(NMdata) {
    console.log(NMdata);
    return apiClient.post("?method=addNMcom", NMdata);
  },
  getMainImage(id) {
    return apiClient.get("media/" + id);
  },
};
