<template>
  <div class="container home">
<!-- <login /> -->
<i class="fas fa-spinner"></i>
  <h1 class="title is-1">Choose a Location </h1>

  <div class="columns">
    <div class="column" v-for="(loco, index) in locations" :key="index">
      <a v-on:click="chooseLoco(loco)">
      <div class="box">
      <p class="title is-3">{{loco}}</p>
       <span style="font-size: 3em; color: Tomato;">
       <font-awesome-icon icon="hospital"  />
     </span>
     </div>
   </a>
    </div>
   
  </div>
  <transition name="fade">
    
    <div v-if="isLoading == true">
    <span style="font-size: 3em; color: Tomato;"><font-awesome-icon icon="circle-notch" class="fa-spin"  /></span>
  </div>
</transition>
  <transition name="fade">
    <div v-if="isLoading == false">
       
        <list :locoProp="locoProp"></list> 
      
      
    </div>
    </transition>
    
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/components/list.vue";
// import login from "@/components/login.vue";
// import { useAuth0 } from '@auth0/auth0-vue';
import { mapState } from "vuex";

export default {
  name: "Home",
  
  components: {
    list,
    // login,
  },
  data() {
    return {
      locations:["EMCM","EMCP","MREP"],
      locoProp:"",
      isLoading: false,
    };
  },
  // setup() {
  //     const { loginWithRedirect, user, isAuthenticated } = useAuth0();

  //     return {
  //       login: () => {
  //         loginWithRedirect();
  //       },
  //       user,
  //       isAuthenticated
  //     };
  //   },
  computed: {
    notNurse() {
      return this.isAllowed;
    },
    ...mapState(["isAllowed"]),
  },
  methods:{
    chooseLoco(loco){
      this.isLoading = true;
      this.locoProp = loco;
      setTimeout(() => this.isLoading = false,  1200);
     
    }, // end chooseLoco
    
  }
};
</script>
<style lang="css" scoped>
 
 .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;

}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
  
</style>
