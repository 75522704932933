import { createStore } from "vuex";
import EventService from "@/services/eventservice.js";

export default createStore({
  state: {
    userDetails: [],
    isloggedIn: false,
    inventory: [],
    loadingstatus: "notloading",
  },
  getters: {
    inventory: (state) => state.inventory,
  },
  mutations: {
    showInventory(state, invTotal) {
      state.inventory = invTotal.inventory;
    },
    SET_LOADING_STATUS(state, status) {
      state.loadingstatus = status;
    },
  },
  actions: {
    getInv({ commit }, invTotal) {
      commit("SET_LOADING_STATUS", "loading");
      EventService.getBloods(JSON.stringify(invTotal))
        .then((response) => {
          console.log(response.data);
          let requests = response.data;

          console.log(requests);
          // for (let i = 0; i < requests.length; i++) {

          console.log("im in");
          // console.log(i);
          commit("SET_LOADING_STATUS", "notLoading");
          commit("showInventory", {
            // state:  results
            inventory: requests,
            // thanks: "formComplete",
          });
          // } // end for
        })
        .catch((error) => {
          console.log("Error adding Requests" + error.response);
        });
    },
  },
});
