import App from "./App.vue";
import { createApp } from "vue";
// import { createAuth0 } from '@auth0/auth0-vue';
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bulma/css/bulma.css";
// import { domain, clientId as client_id } from "./auth_config.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library https://stackoverflow.com/questions/66389974/using-font-awesome-in-vue-3 */
// https://dev.to/sabbirsobhani/font-awesome-with-vuejs-3-59ee add all the icons
library.add(fas);


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const BloodBank = createApp(App);

// /* add font awesome icon component */
BloodBank.component("font-awesome-icon", FontAwesomeIcon);

BloodBank.config.productionTip = false;

BloodBank.use(router).use(store);

// BloodBank.use(
// 	createAuth0({
//     domain,
//     client_id,
//     redirect_uri: window.location.origin
// 	})
// );
// now we're ready to mount
BloodBank.mount("#app");
